export const menuItems = [
  {
    name: "services",
    label: "Services",
    items: [
      { name: "marketing-strategy-branding", label: "Marketing Strategy & Branding" },
      { name: "content-marketing-seo", label: "Content Marketing & SEO" },
      { name: "product-marketing", label: "Product Focused Marketing" },
      { name: "digital-experience-design", label: "Digital Experience Design" },
      { name: "marketing-automation", label: "Marketing Automation" },
      { name: "video-production", label: "Video Production" },
    ],
  },
  {
    name: "company",
    label: "Company",
  },
  {
    name: "blog",
    label: "Blog",
  },
  /*{
    name: "case-studies",
    label: "Case Studies",
  },*/
  {
    name: "contact-us",
    label: "Contact Us",
  },
];
